<template>
  <el-dialog
    :title="$t('edit_email')"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="140px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item :label="$t('username')" prop="userName">
        <el-input
          v-model="dataForm.userName"
          disabled
          style="width: 90%"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('new_email')"
        prop="email"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.email"
          type="input"
          :placeholder="$t('enter_new_email')"
          style="width: 75%"
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getCode"
          :disabled="value"
          style="margin-left: 10px"
        >
          {{ typeof text == 'number' ? text + second : text }}
        </el-button>
      </el-form-item>
      <el-form-item
        :label="$t('verification_code')"
        prop="code"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.code"
          type="input"
          :placeholder="$t('verification_code')"
          style="width: 90%"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{
        $t('cancel')
      }}</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >{{ $t('confirm') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { userInfo, updateEmail, setSendEmail } from '@/api/sys'
import { isEmail } from '@/utils/validate'
export default {
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error(this.$t('e710', {x: this.$t('email')})))
      } else {
        callback()
      }
    }
    return {
      text: this.$t('send_code'),
      value: false,
      second: 'S',
      visible: false,
      dataLoading: false,
      email: '',
      dataForm: {
        id: 0,
        userName: '',
        email: '',
        code: ''
      },
      dataRule: {
        code: [
          { required: true, message: this.$t('e706', {x: this.$t('verification_code')}), trigger: 'blur' }
        ],
        email: [
          {
            required: true,
            message: this.$t('enter_valid_email'),
            trigger: 'blur'
          },
          { validator: validateEmail, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    //根据用户邮箱发送验证码
    getCode() {
      console.log('发送验证码')
      localStorage.setItem('email', this.dataForm.email)
      var params = {
        email: this.dataForm.email
      }
      setSendEmail(params).then(({ data }) => {
        console.log(data)
        let codeData = data
        if (codeData.code === 0) {
          this.$message({
            showClose: true,
            message: this.$t('success'),
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
          this.dataLoading = false
        }
      })
    },
    init(id) {
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.dataForm.userName = ''
        if (this.dataForm.id) {
          var params = {
            userId: this.dataForm.id
          }
          userInfo(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.userName = data.user.userName
              this.dataForm.email = ''
              this.dataForm.code = ''
              this.value = false
              this.text = this.$t('send_code')
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var params = {
            userId: this.dataForm.id || undefined,
            email: this.dataForm.email,
            code: this.dataForm.code
          }
          updateEmail(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    }
  }
}
</script>
