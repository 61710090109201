<template>
  <div class="main-el-menu">
    <el-menu class="navbar" mode="horizontal">
      <hamburger
        class="hamburger-container"
        :toggleClick="toggleSideBar"
        :isActive="sidebar.opened"
      ></hamburger>
      <breadcrumb></breadcrumb>
      <announcement></announcement>
      <div class="right-menu">
        <template>
          <!--<router-link to="/warning">
          <svg-icon class="warning" icon-class="waring"></svg-icon>
          </router-link>-->
          <router-link
            target="_blank"
            :to="{ path: '/datav' }"
            v-if="hasKanBan == '1'"
          >
            <img
              style="height: 22px; margin-right: 30px"
              src="@/assets/index/datav.png"
            />
          </router-link>
          <!-- <img
            style="width: 28px; height: 28px; margin-right: 10px"
            src="@/assets/index/gwc.png"
          /> -->
          <a
            class="help-span"
            href="https://www.yitu.info/helpcenter"
            target="_blank"
            :title="$t('help_center')"
            ><svg-icon icon-class="help" class-name="help-icon"
          /></a>
          <img
            style="
              width: 11px;
              height: 16px;
              margin-right: 15px;
              cursor: pointer;
            "
            @click="handleDeviceBoot"
            v-if="!device.deviceName"
            src="@/assets/index/sb.png"
          />
        </template>
        <!-- <span class="mr10">当前设备：</span> -->
        <div
          class="device-status"
          @click="handleDeviceBoot"
          v-if="device.deviceName"
        >
          <span style="padding-right: 5px">
            {{ device.deviceName }}
          </span>
          <img
            v-if="device.connectionType == '0'"
            src="../../../assets/usb_dark.png"
            height="18"
            width="18"
          />
          <img
            v-else
            src="../../../assets/usb_placeholder.png"
            height="18"
            width="18"
          />
          <img
            v-if="device.connectionType == '1'"
            src="../../../assets/wireless_connection_dark.png"
            height="24"
            width="24"
          />
          <img
            v-else
            src="../../../assets/wireless_connection_placeholder.png"
            height="24"
            width="24"
          />
        </div>
        <div
          class="device-status"
          @click="handleDeviceBoot"
          style="color: #c0c4cc"
          v-else
        >
          {{ $t('no_device_connected') }}
        </div>

        <!-- 
        <template>
            <a @click="handleDeviceBoot">
            <el-input
              v-model="device.deviceName"
              :placeholder="$t('no_device_connected')"
              size="small"
              readonly
              style="
                min-width: 80px;
                max-width: 200px;
                width: auto;
                color: #171616;
              "
              maxlength="30"
            ></el-input> -->
        <!-- <el-select v-model="device.deviceId" placeholder="设备选择" @click="changeValue" style="width: auto;color:#171616">
            <el-option v-for="item in deviceList" :key="item.deviceId" :label="item.deviceName" :value="item.deviceId">
            </el-option>
          </el-select> 
          </a>
        </template>-->
        <div style="margin-left: 20px">
          <LocaleSwitcher />
        </div>
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <!-- <img class="user-avatar" src="../../../assets/img/avatar.png" /> -->
            <div>
              &nbsp;<span style="color: #000"
                >{{ vo.roleName }}&nbsp;&nbsp;</span
              >&nbsp;<span style="color: #999">{{
                setUserName(vo.userName)
              }}</span>
            </div>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="user-dropdown" slot="dropdown">
            <el-dropdown-item
              >{{ $t('account') }}: {{ vo.userName }}</el-dropdown-item
            >
            <el-dropdown-item
              >{{ $t('email') }}:
              {{ vo.email ? vo.email : $t('none') }}</el-dropdown-item
            >
            <el-dropdown-item
              >{{ $t('role') }}: {{ vo.roleName }}</el-dropdown-item
            >
            <el-dropdown-item>
              <el-button
                type="primary"
                size="small"
                @click="updatepasswordHandle(vo.userId)"
                >{{ $t('change_password') }}</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="updatepemailHandle(vo.userId)"
                >{{ $t('edit_email') }}</el-button
              >
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span @click="logout" style="display: block">
                {{ $t('logout') }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-menu>
    <update-password
      v-if="updatePasswordVisible"
      ref="updatePassword"
    ></update-password>
    <update-email v-if="updateEmailVisible" ref="updateEmail"></update-email>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { nameAndRole } from '@/api/sys'
import UpdatePassword from '@/components/updatepassword'
import UpdateEmail from '@/components/updateEmail'
import { selectBrandInfo } from '@/api'
import { setUser } from '@/utils/auth'
import Announcement from './announcement.vue'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      websock: null,
      deviceBootShow: false,
      hasKanBan: '',
      vo: {
        userId: '',
        userName: '',
        roleName: ''
      },
      updatePasswordVisible: false,
      updateEmailVisible: false,
      deviceList: [],
      device: {
        deviceId: '',
        deviceName: '',
        connectionType: ''
      }
    }
  },
  components: {
    LocaleSwitcher,
    Breadcrumb,
    Hamburger,
    UpdatePassword,
    UpdateEmail,
    DeviceBoot: () => import('@/components/DeviceBoot'),
    Announcement
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'userDevice']),
    setUserName() {
      //text就是所传参数
      return function (text = '') {
        if (text.length > 14) {
          return text.slice(0, 15) + '...'
        } else {
          return text
        }
      }
    }
  },
  watch: {
    'userDevice.id'(newval, olval) {
      this.deviceList = []
      this.device.deviceId = newval
      this.device.deviceName = this.userDevice.deviceName
      ;(this.device.connectionType = this.userDevice.connectionType),
        this.deviceList.push({
          deviceName: this.userDevice.deviceName,
          connectionType: this.userDevice.connectionType,
          deviceId: newval
        })
      if (this.websock) {
        this.websock.close()
      }
      this.initWebSocket()
    }
    // userDevice(newVal, oldVal) {
    //   // 数据变化后，调用b组件内的函数
    //   console.log(newVal, oldVal)
    // }
  },
  created() {
    const userDevice = this.$store.getters.userDevice
    const cookieDevice = Cookies.getJSON('userDevice')
    if (userDevice && userDevice.id) {
      this.initWebSocket()
      this.device.deviceId = userDevice.id
      this.device.deviceName = userDevice.deviceName
      this.device.connectionType = userDevice.connectionType
      this.deviceList.push({
        deviceName: userDevice.deviceName,
        deviceId: userDevice.id
      })
    } else if (cookieDevice && cookieDevice.id && cookieDevice.connectionType == 1) {
      // store没有设备，但是曲奇有。这个无疑是Wifi设备
      this.$store.dispatch('SetUserDevice', cookieDevice).then(() => {
        // console.log(this.$store.getters.userDevice)
      })
      this.device.deviceId = cookieDevice.id
      this.device.deviceName = cookieDevice.deviceName
      this.device.connectionType = cookieDevice.connectionType
      this.deviceList.push({
        deviceName: cookieDevice.deviceName,
        deviceId: cookieDevice.id
      })
    }
    this.getNameAndRole()
    this.selectBrandInfo()
  },
  methods: {
    handleDeviceBoot() {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init()
        })
      })
    },
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      if (this.websock) {
        this.websock.close()
      }
      const userDevice = this.$store.getters.userDevice
      userDevice.id = ''
      userDevice.deviceName = ''
      userDevice.connectionType = ''
      this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        console.log(this.$store.getters.userDevice)
      })
      this.device.deviceId = ''
      this.device.deviceName = ''
      Cookies.remove('userDevice')
      sessionStorage.removeItem('testKey')
      this.$store.dispatch('LogOut').then(() => {
        // this.$message({
        //   message: '85285',
        //   type: 'warning',
        //   showClose:true,
        //   duration: 0,
        // })
        sessionStorage.removeItem('nameAndRoleSysOrder')
        console.log('登出')
        location.reload() // 为了重新实例化vue-router对象 避免bug
        console.log('完成')
      })
      //Cookies.set(deviceId, '')
    },
    //导航栏下拉框
    changeValue(val) {
      //Cookies.set(deviceId, val)
      this.handleDeviceBoot()
    },
    //获取已授权设备
    // getselectDevice() {
    //   let params = {
    //     mac: ""
    //   }
    //   selectDevice(params).then(({ data }) => {
    //     this.$nextTick(() => {
    //       if (data && data.code === 0) {
    //         this.deviceList = data.deviceList;
    //         if (data.deviceList.length > 0) {
    //           this.device.deviceId = data.deviceList[0].deviceId;
    //         }
    //         Cookies.set(deviceId, this.device.deviceId)
    //       } else {
    //         this.deviceList = []
    //       }
    //     })
    //   })
    // },
    //修改密码
    updatepasswordHandle(id) {
      this.updatePasswordVisible = true
      console.log(id)
      if (id == null) {
        this.$message.error(this.$t('account_error'))
        return
      }
      this.$nextTick(() => {
        this.$refs.updatePassword.init(id)
      })
    },
    //修改邮箱
    updatepemailHandle(id) {
      this.updateEmailVisible = true
      console.log(id)
      if (id == null) {
        this.$message.error(this.$t('account_error'))
        return
      }
      this.$nextTick(() => {
        this.$refs.updateEmail.init(id)
      })
    },
    //获取用户账号和用户角色
    getNameAndRole() {
      let params = {}
      nameAndRole(params).then(({ data }) => {
        if (data && data.code === 0) {
          sessionStorage.setItem('nameAndRoleSysOrder', data.vo.sysOrder)
          // sessionStorage.setItem("nameAndRoleSysOrder",'3');
          this.vo = data.vo
          sessionStorage.setItem(
            'factoryId',
            data.vo.factoryId ? data.vo.factoryId : ''
          )
          sessionStorage.setItem('userId', data.vo.userId ? data.vo.userId : '')
          sessionStorage.setItem(
            'userName',
            data.vo.userName ? data.vo.userName : ''
          )
        } else {
          this.vo = {}
        }
      })
    },
    async selectBrandInfo() {
      const { data } = await selectBrandInfo()
      if (data && data.code === 0) {
        this.hasKanBan = data.data.hasKanBan
        setUser(data.data)
        sessionStorage.setItem(
          'BrandInfo',
          data.data.brandId ? data.data.brandId : ''
        )
      }
    },
    initWebSocket() {
      //初始化weosocket
      //ws地址
      var id = this.$store.getters.userDevice.id
      if (null == id || '' == id) {
        if (this.websock) {
          this.websock.close()
        }
        return
      }
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/device_state_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      console.log(e)
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      console.log(code)
      var msg = retData.msg
      console.log(msg)
      var data = retData.data
      console.log(data)
      if (code == 1) {
        this.websock.close()
        const userDevice = this.$store.getters.userDevice
        userDevice.id = ''
        userDevice.deviceName = ''
        userDevice.connectionType = ''
        this.device.deviceId = ''
        this.device.deviceName = ''
        this.device.connectionType = ''
        this.$store.dispatch('SetUserDevice', userDevice).then(() => {
          console.log(this.$store.getters.userDevice)
        })
        this.deviceBootShow = false
        this.$message.error(this.$t('device_disconnected'))
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error(this.$t('websocket_connection_failed'))
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      console.log('connection closed (' + e.code + ')')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .right-menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // float: right;
    // height: 100%;
    // line-height: 50px;
    // width: 20%;
    // margin-right: 150px;
  }
  .warning {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    font-size: 32px;
  }
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    margin-right: 45px;
    margin-left: 30px;
    // position: absolute;
    // right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-items: center;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-right: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
.device-status {
  min-width: 110px;
  max-width: 300px;
  height: 32px;
  width: auto;
  color: #171616;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  margin-top: 2px;
  line-height: 0px;
  display: flex;
  align-items: center;
  img {
    vertical-align: middle;
  }
}
.help-span {
  cursor: pointer;
  .help-icon {
    font-size: 28px;
    float: left;
    margin-right: 15px;
    color: #606266;
  }
}
.main-el-menu {
  .navbar.el-menu--horizontal.el-menu {
    display: flex;
  }
  .el-badge__content.is-fixed {
    top: 13px;
  }
  .el-input--suffix .el-input__inner {
    background: #edeef2;
    border-color: #ccc;
    height: 32px;
    width: 100%;
    border-radius: 0;
  }
}
</style>
