<template>
  <div class="locale-switcher">
    <el-select
      v-model="$i18n.locale"
      size="small" @input="cacheLocale($i18n.locale)"
    >
      <el-option
        :value="locale.code"
        v-for="locale in locales"
        :key="locale.code"
        :label="locale.name"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getSupportedLocales } from '@/util/i18n/supported-locales'
import Cookies from 'js-cookie'

export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    cacheLocale(locale) {
      Cookies.set('locale', locale)
    }
  }
}
</script>