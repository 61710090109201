import axios from 'axios'
import {
  Message
} from 'element-ui'
import store from '../store'
import {
  getToken
} from '@/utils/auth'
import { localizeReturnValue } from '../util/i18n/general'
import i18n from '../i18n'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 300000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  if (process.env.NODE_ENV === 'development') {
    config.url = '/api' + config.url;
  } else {
    if (config.url.indexOf('colorx-tigerApi') != -1) {
      config.url = process.env.VUE_APP_BASE_API.replace('/tiger-admin/', '') + config.url;
    } else {
      config.url = process.env.VUE_APP_BASE_API + config.url;
    }
  }
  if (store.getters.token) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['locale'] = i18n.locale
  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // 消息的国际化
    response.data.msg = localizeReturnValue(res);

    if (res.code === 401) {
      store.dispatch('FedLogOut').then(() => {
        location.reload()
      })
    } else {
      return response
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message, // ,
      type: 'error',
      duration: 10 * 1000
    })
    return Promise.reject(error)
  })

export default service