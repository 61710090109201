import i18n from "../../i18n"

export function localizeParam(item, param) {
    if (
        i18n.locale == 'zh' ||
        !item[param + "En"] ||
        item[param + "En"].length == 0
    ) {
        return item[param]
    }
    return item[param + "En"]
}

export function localizeAsset(assetName) {
    let name = assetName.substring(0, assetName.lastIndexOf("."));
    let extension = assetName.substring(assetName.lastIndexOf(".") + 1);
    // 先试试看有没有国际化版本的文档
    try {
        let localizedFileName = name + "_" + i18n.locale + "." + extension;
        return require('@/assets/' + localizedFileName);
    } catch (e) {
        // 如果失败了，那么只能用原本的文档
        return require('@/assets/' + assetName);
    }
}

/**
 * 
 * @param {obj} data 后端返回的错误
 */
export function localizeReturnValue(data) {

    // 没有数据，返回默认异常
    if (!data) {
        return i18n.t('default_error')
    }

    // 如果错误码在国家化字典里面，返回国际化国际化

    if (data.code !== 0) {
        var localizedCode = i18n.t('e' + data.code);
        if (localizedCode != 'e' + data.code) {
            if (localizedCode.match('{x}') != null) {
                if (i18n.t(data.msg) != data.msg) {
                    return i18n.t('e' + data.code, {x: i18n.t(data.msg)})
                }
                // 错误是国际化的（包含参数），但是msg没有提供国际化字典的字符，所以还是返回原来的消息
                return data.msg;
            }
        
            return i18n.t('e' + data.code)
        }
    }

    // 国际化字典有这个key，返回国际化的msg
    if (i18n.t(data.msg) != data.msg) {
        return i18n.t(data.msg)
    }
    return data.msg;
}