import { menuNav } from "@/api/nav";
import { asyncRouterMap, constantRouterMap } from "@/router";
import Layout from "@/views/layout/Layout";
import { localizeParam } from "../../util/i18n/general";

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
// function hasPermission(roles, route) {
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.indexOf(role) >= 0)
//   } else {
//     return true
//   }
// }

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
// function filterAsyncRouter(asyncRouterMap, roles) {
//   const accessedRouters = asyncRouterMap.filter(route => {
//     if (hasPermission(roles, route)) {
//       if (route.children && route.children.length) {
//         route.children = filterAsyncRouter(route.children, roles)
//       }
//       return true
//     }
//     return false
//   })
//   return accessedRouters
// }

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    permissions: [],
    indexPermissions: [],
    userDevice: null,
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_INDEX_PERMISSIONS: (state, indexPermissions) => {
      state.indexPermissions = indexPermissions;
    },
    SET_USER_DEVICE: (state, userDevice) => {
      state.userDevice = userDevice;
    },
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        menuNav()
          .then(({ data }) => {
            if (!data.code) {
              const vData = data.menuList;
              const newDatas = vData.map((v) => {
                const newRouters = {
                  path: "",
                  component: Layout,
                  name: v.url,
                  meta: {
                    title: localizeParam(v, 'name'),
                    icon: v.icon,
                  },
                  children:
                    v.list === null
                      ? []
                      : v.list.map((res) => {
                          let path = res.url,
                            component = res.url;
                          if (res.type == 3) {
                            // 私有菜单
                            path = "iframe-" + res.perms;
                            component = "iframe";
                          }
                          return {
                            path: path,
                            name: res.url,
                            component: loadView(component),
                            meta: { title: localizeParam(res, 'name'), path: res.url },
                          };
                        }),
                };
                return newRouters;
              });
              const accessedRouters = [...asyncRouterMap, ...newDatas];
              commit("SET_ROUTERS", accessedRouters);
              commit("SET_PERMISSIONS", data.permissions);
              commit("SET_INDEX_PERMISSIONS", data.modularList);
              // commit('SET_INDEX_PERMISSIONS', ['1','4','6','9','10'])
              commit("SET_USER_DEVICE", data.userDevice);
              localStorage.setItem("brandLogo", data.brandLogo);
              localStorage.setItem("brandWxqr", data.brandWxqr);
            }
            resolve(data.code);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    SetUserDevice({ commit }, userDevice) {
      commit("SET_USER_DEVICE", userDevice);
    },
  },
};

/**
 * 路由懒加载
 */
export const loadView = (view) => {
  // webpack4中动态import不支持变量方式
  return (resolve, reject) => require([`@/views/${view}`], resolve);
};

export default permission;

// {
//   path: '/example',
//   component: Layout,
//   redirect: '/example/table',
//   name: 'Example',
//   meta: { title: 'Example', icon: 'example' },
//   children: [
//     {
//       path: 'table',
//       name: 'Table',
//       component: () => import('@/views/table/index'),
//       meta: { title: 'Table', icon: 'table' }
//     },
//     {
//       path: 'tree',
//       name: 'Tree',
//       component: () => import('@/views/tree/index'),
//       meta: { title: 'Tree', icon: 'tree' }
//     }
//   ]
// },
// {
//   path: '/icon',
//   component: Layout,
//   children: [{
//     path: 'index',
//     component: () => import('@/views/svg-icons/index'),
//     name: 'icons',
//     meta: { title: 'icons', icon: 'icon', noCache: true }
//   }]
// }
