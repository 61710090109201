<template>
  <div
    v-if="announcementList && announcementList.length > 0"
    class="marquee-container"
  >
    <div class="marquee">
      <div class="marquee_box">
        <ul class="marquee_list" :class="{ marquee_top: animate }">
          <li
            v-for="(item, index) in announcementList"
            :key="index"
            @click="showAnnouncement(item)"
          >
            <i class="el-icon-bell" style="color: red"></i
            ><span>{{ localizeParam(item, 'title') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :title="localizeParam(announcement, 'title')"
      width="800px"
    >
      <div class="dialog">
        <el-form>
          <el-form-item class="content">
            <div v-html="localizeParam(announcement, 'content')"></div>
          </el-form-item>
        </el-form>
        <span class="back-button">
          <el-button type="primary" @click="dialogVisible = false">{{
            $t('close')
          }}</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getActiveAnnouncementApi, addClickAnnouncementApi } from '@/api/sys'

export default {
  data() {
    return {
      // 公告列表
      announcementList: null,
      announcement: null,
      animate: false,
      dialogVisible: false
    }
  },
  created() {
    this.getActiveAnnouncement()
  },
  methods: {
    // 获取active的公告
    getActiveAnnouncement() {
      getActiveAnnouncementApi().then(({ data }) => {
        if (data && data.code === 0 && data.announcement) {
          this.announcementList = [data.announcement, data.announcement]
          setInterval(this.showMarquee, 3000)
        }
      })
    },
    showMarquee() {
      this.animate = true
      setTimeout(() => {
        this.announcementList.push(this.announcementList[0])
        this.announcementList.shift()
        this.animate = false
      }, 500)
    },
    
    // 展示公告详情
    showAnnouncement(row) {
      this.announcement = row
      this.dialogVisible = true
      addClickAnnouncementApi({ id: row.id })
    }
  }
}
</script>

<style lang="scss">
.marquee-container {
  display: inline-block;
  min-width: calc(100% - 1100px);
  max-width: calc(100% - 700px);
  .el-form-item {
    margin-bottom: 0;
  }
  .marquee {
    width: 99%;
    height: 50px;
    align-items: center;
    color: #3a3a3a;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    .marquee_box {
      display: block;
      position: relative;
      width: 99%;
      height: 30px;
      overflow: hidden;

      .marquee_list {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        li {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          padding-left: 20px;
          list-style: none;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            padding: 0 2px 0 5px;
          }
        }
      }
      .marquee_top {
        transition: all 0.5s;
        margin-top: -30px;
      }
    }
  }
}
.dialog {
  margin-top: -20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 20px 20px 50px 40px;
}
.back-button {
  position: absolute;
  right: 30px;
  bottom: 33px;
}
</style>
